.block-offers-search {
	padding-bottom: 98px;
}
.block-search-field-salary {
	width: 100%;
	height: 54px;
	background: #FFFFFF;
	border: 1px solid rgba(42, 39, 76, 0.3);
	border-radius: 15px;
	outline: none;
	color: #101010;
	padding: 14px 16px;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.block-search-field-salary span {
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
	margin-bottom: 7px;
}
.block-search-field-salary.dropdown {
	height: auto;
}
.block-search-field-salary-dropdown-fields {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	grid-column-gap: 3px;
	grid-row-gap: 3px;
	font-size: 13px;
	font-weight: 400;
	line-height: 18.2px;
	color: #10101080;
	margin-bottom: 21px;
}
.block-search-field-salary-dropdown-fields > div {
	display: flex;
	justify-content: center;
	align-items: center;
	grid-column-gap: 3px;
}
.block-search-field-salary-dropdown-fields input {
	width: 80px;
	height: 28px;
	border: 0.5px solid #2A274CCC;
	border-radius: 10px;
	text-align: center;
	outline: none;
}
.block-search-field-salary .multi-range-slider .thumb::before {
	width: 4px;
	height: 24px;
	border-radius: 4px;
	margin: -8px -1px;
	background: #D9D9D9;
	border: none;
	box-shadow: none;
}
.block-search-field-salary .multi-range-slider .bar-inner, 
.block-search-field-salary .multi-range-slider .bar-left, 
.block-search-field-salary .multi-range-slider .bar-right {
	border: 0.5px solid #2A274CCC;
	box-shadow: none;
}
.block-offer-items {
	display: flex;
	flex-direction: column;
	grid-row-gap: 15px;
}
.block-offer-items-switch {
	display: flex;
	align-items: center;
	grid-column-gap: 16px;
}
.block-offer-items-switch-item {
	font-size: 12px;
	font-weight: 400;
	line-height: 13.2px;
	cursor: pointer;
}
.block-offer-items-switch-item.active {
	font-weight: 900;
}
.block-offer-items-list {
	display: flex;
	flex-direction: column;
	grid-row-gap: 22px;
	height: 668px;
	padding-right: 30px;
	overflow-y: scroll;
}
.block-offer-item {
	width: 100%;
	min-height: 116px;
	position: relative;
	border: 0.5px solid #2A274C;
	border-radius: 10px;
	padding: 14px 18px 11px 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	grid-column-gap: 32px;
	overflow: hidden;
}
.block-offer-item-promoted {
	position: absolute;
	top: 0;
	left: 0;
	width: 21px;
	height: 100%;
	background: #F3B119;
	font-size: 11px;
	font-weight: 700;
	line-height: 12.1px;
	text-align: center;
	color: #FFFFFF;
	display: flex;
	justify-content: center;
	align-items: center;
}
.block-offer-item-promoted div {
	writing-mode: vertical-rl;
	transform: rotate(180deg);
}
.block-offer-item-shadow {
	display: none;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: #FFFFFFB5;
	border-radius: 10px;
	justify-content: center;
	align-items: center;
}
.block-offer-item:hover > .block-offer-item-shadow {
	display: flex;
}
.block-offer-item-actions {
	width: 805px;
	height: 39px;
	border-radius: 10px;
	background: #2A274C;
	display: flex;
	justify-content: center;
	align-items: center;
	grid-column-gap: 10px;
}
.block-offer-item-action {
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 400;
	line-height: 16.8px;
	color: #FFFFFF80;
	cursor: pointer;
	display: flex;
	align-items: center;
	grid-column-gap: 5px;
}
.block-offer-item-action.highlighted {
	color: #FFFFFF;
}
.block-offer-item-logo {
/*	width: 164px;*/
	height: 72px;
}
.block-offer-item-logo img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}
.block-offer-item-info {
	width: 807px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.block-offer-item-info-top {
	display: flex;
	justify-content: space-between;
}
.block-offer-item-info-title-offer {
	font-size: 20px;
	font-weight: 700;
	line-height: 28px;
	text-align: left;
}
.block-offer-item-info-title-company {
	font-size: 16px;
	font-weight: 700;
	line-height: 22.4px;
	text-align: left;
}
.block-offer-item-info-options {
	display: flex;
	align-items: center;
	grid-column-gap: 10px;
}
.block-offer-item-info-options-types span {
	font-size: 16px;
	font-weight: 700;
	line-height: 17.6px;
	text-align: center;
	padding: 0 8px;
	cursor: pointer;
	color: #000;
	opacity: .3;
}
.block-offer-item-info-options-types span.active {
	background: #F2D70F;
	color: #2A274C;
	opacity: 1;
}
.block-offer-item-info-options-favorite {
	margin: 0 !important;
	cursor: pointer;
}
.block-offer-item-info-bottom {
	display: flex;
	align-items: center;
}
.block-offer-item-info-features {
	display: flex;
	align-items: center;
}
.block-offer-item-info-features-item {
	display: flex;
	align-items: center;
	grid-column-gap: 5px;
}
.block-offer-item-info-features-item:nth-child(1) {
	width: 174px;
}
.block-offer-item-info-features-item:nth-child(2) {
	width: 127px;
}
.block-offer-item-info-features-item:nth-child(3) {
	width: 183px;
}
.block-offer-item-info-features-item:nth-child(4) {
	width: 158px;
}
.block-offer-item-info-features-item:nth-child(5) {
	width: auto;
}
.block-offer-item-info-features-item span {
	font-size: 12px;
	font-weight: 400;
	line-height: 13.2px;
	text-align: left;
}

.block-offer-types {
	display: flex;
	justify-content: center;
	align-items: center;
}
.block-offer-types span {
	font-size: 20px;
	font-weight: 700;
	line-height: 22px;
	text-align: center;
	padding: 0 11px 3.5px;
	border-radius: 3.5px;
	color: #000;
	opacity: .3;
}
.block-offer-types span.active {
	background: #F2D70F;
	color: #2A274C;
	opacity: 1;
}
.block-offer-delimiter {
	width: 100%;
	border: 1px solid #D9D9D9;
	margin: 16px auto;
}
.block-offer-top {
	display: flex;
	justify-content: space-between;
}
.block-offer-top-left {
	max-width: 644px;
}
.block-offer-favorite {
	margin-top: 5px;
	cursor: pointer;
}
.block-offer-title {
	font-size: 36px;
	font-weight: 700;
	line-height: 43.2px;
	text-align: left;
	margin-top: 16px;
	margin-bottom: 16px;
}
.block-offer-company {
	font-size: 24px;
	font-weight: 500;
	line-height: 28.8px;
	text-align: left;
	margin-bottom: 42px;
}
.block-offer-features {
	width: 556px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	grid-column-gap: 35px;
	grid-row-gap: 13px;
}
.block-offer-features-item {
	display: flex;
	align-items: center;
	grid-column-gap: 5px;
}
.block-offer-features-item span {
	font-size: 16px;
	font-weight: 400;
	line-height: 17.6px;
	text-align: left;
}
.block-offer-features-item-money span {
	font-size: 20px;
	font-weight: 700;
	line-height: 22px;
}
.block-offer-top-right {
	width: 318px;
}
.block-offer-logo {
	width: 100%;
	height: 193px;
	margin-bottom: 29px;
}
.block-offer-logo img {
	width: 100%;
	height: 100%;
	object-fit: contain;
	object-position: center;
}
.block-offer-ref {
	margin-left: 30px;
}
.block-offer-ref-caption {
	margin-left: 3px;
	margin-bottom: 10px;
	font-size: 16px;
	font-weight: 500;
	line-height: 19.2px;
	text-align: left;
}
.block-offer-ref-user {
	display: flex;
	grid-column-gap: 19px;
}
.block-offer-ref-user-photo {
	width: 52.87px;
	height: 52.98px;
	border-radius: 100%;
	background: linear-gradient(-16deg, rgba(42,39,76,.8) 0%, #5C8CC9 100%);
	padding: 5px;
	overflow: hidden;
}
.block-offer-ref-user-photo > div {
	width: 100%;
	height: 100%;
	border-radius: 100%;
	overflow: hidden;
}
.block-offer-ref-user-photo > div > img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}
.block-offer-ref-user-data-name {
	font-size: 16px;
	font-weight: 900;
	line-height: 19.2px;
	margin-bottom: 2px;
}
.block-offer-ref-user-data-position {
	font-size: 12px;
	font-weight: 400;
	line-height: 14.4px;
}
.block-offer-ref-user-data-chat {
	cursor: pointer;
}
.block-offer-body {
	margin: 62px auto;
	font-size: 16px;
	font-weight: 500;
	line-height: 19.2px;
	text-align: left;
}
.block-offer-bottom {
	display: flex;
	flex-direction: column;
	grid-row-gap: 18px;
}
.block-offer-buttons {
	display: flex;
	justify-content: center;
	align-items: center;
	grid-column-gap: 28px;
}
.block-offer-buttons .btn {
	width: 232px;
}

.block-offers-company-head {
	display: flex;
	grid-column-gap: 30px;
}
.block-offers-company-logo {
	width: 128px;
	height: 128px;
}
.block-offers-company-logo img {
	width: 100%;
	height: 100%;
	object-fit: contain;
	object-position: center;
}
.block-offers-company-widgets {
	display: flex;
	justify-content: center;
	align-items: center;
	grid-column-gap: 33px;
	margin-top: 63px;
}
.block-offers-widget {
	width: 478px;
	display: flex;
	flex-direction: column;
	grid-row-gap: 25px;
}
.block-offers-widget-title {
	font-size: 26.24px;
	font-weight: 400;
	line-height: 31.49px;
	text-align: left;
}
.block-offers-widget-body {
	width: 100%;
	height: 127px;
	border-radius: 10px;
	border: 0.5px solid #000000;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 13px 0;
}
.block-offers-widget-body-delimiter {
    position: relative;
/*    margin-bottom: 20px;*/
	width: 0;
    height: 100%;
    border-left: 1px dashed rgba(0,0,0,.5);
    z-index: 1;
}
.block-offers-widget-body-delimiter:before,
.block-offers-widget-body-delimiter:after {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    left: calc(50% - 3px);
    background: rgba(0,0,0,.8);
    border-radius: 50%;
    z-index: 2;
}
.block-offers-widget-body-delimiter:before {
    top: -3px;
}
.block-offers-widget-body-delimiter:after {
    bottom: -3px;
}
.block-offers-widget-body-item {
	width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	grid-row-gap: 15px;
}
.block-offers-widget-body-item-caption {
	font-size: 16px;
	font-weight: 400;
	line-height: 19.2px;
	text-align: center;
	color: #101010CC;
}
.block-offers-widget-body-item-value {
	font-size: 26px;
	font-weight: 400;
	line-height: 31.2px;
	text-align: center;
	color: #101010CC;
}
.block-offers-company-add {
	width: 100%;
	margin: 42px auto;
	display: flex;
	justify-content: center;
}
.block-offers-company-add .btn {
	width: 232px;
}

.block-offer-edit-form-fields {
/*	max-width: 963px;*/
	margin-top: 107px;
}
.block-offer-edit-form-field {
	display: flex;
	align-items: center;
	margin-bottom: 9px;
}
.block-offer-edit-form-field-title {
	margin-bottom: 15px;
}
.block-offer-edit-form-field-icon {
	width: 35px;
	margin-right: 10px;
	display: flex;
	justify-content: flex-end;
}
.block-offer-edit-form-field-location img {
	width: 19px;
	height: 23px;
}
.block-offer-edit-form-field-specialization img {
	width: 27px;
	height: 25px;
}
.block-offer-edit-form-field-label {
	width: 193px;
	font-size: 16px;
	font-weight: 400;
	line-height: 19.2px;
	text-align: left;
}
.block-offer-edit-form-field-input input {
	width: 323px !important;
	height: 31px !important;
	padding: 2px 14px !important;
	border-radius: 6px !important;
	font-size: 16px !important;
	font-weight: 400 !important;
	line-height: 22.4px !important;
}
.block-offer-edit-form-field-title .block-offer-edit-form-field-input {
	width: 100%;
	flex: 1;
}
.block-offer-edit-form-field-title .block-offer-edit-form-field-input input {
	width: 100% !important;
	height: 76px !important;
	padding: 0 26.39px !important;
	font-size: 32px !important;
	font-weight: 900 !important;
	line-height: 38.4px !important;
}
.block-offer-edit-form-field-switcher {
	display: flex;
	align-items: center;
	grid-column-gap: 12px;
}
.block-offer-edit-form-field-switcher div {
	padding: 2px 15px;
	border: 1px solid #2A274CB2;
	border-radius: 6px;
	font-size: 16px;
	font-weight: 400;
	line-height: 27px;
	text-align: center;
	color: #2A274C;
	cursor: pointer;
}
.block-offer-edit-form-field-switcher div.active {
	background: #2A274C;
	color: #FFFFFF;
}
.block-offer-edit-form-field-salary-choose {
	display: flex;
	align-items: center;
}
.block-offer-edit-form-field-salary-choose span {
	font-size: 16px;
	font-weight: 400;
	line-height: 22.4px;
	color: #10101080;
}
.block-offer-edit-form-field-salary-choose-slider {
	width: 267px;
	margin-left: 22px;
	margin-right: 22px;
}
.block-offer-edit-form-field-salary-choose .multi-range-slider .thumb::before {
	width: 4px;
	height: 24px;
	border-radius: 4px;
	margin: -8px -1px;
	background: #D9D9D9;
	border: none;
	box-shadow: none;
}
.block-offer-edit-form-field-salary-choose .multi-range-slider .thumb .caption {
	display: flex;
}
.block-offer-edit-form-field-salary-choose .multi-range-slider .bar-inner, 
.block-offer-edit-form-field-salary-choose .multi-range-slider .bar-left, 
.block-offer-edit-form-field-salary-choose .multi-range-slider .bar-right {
	border: 0.5px solid #2A274CCC;
	box-shadow: none;
}
.block-offer-edit-form-field-salary-choose-fields {
	display: flex;
	align-items: center;
	grid-column-gap: 10px;
	font-size: 16px;
	font-weight: 400;
	line-height: 22.4px;
	color: #101010;
}
.block-offer-edit-form-field-salary-choose-fields input {
	width: 75px;
	height: 31px;
	border: 1px solid #2A274C4D;
	border-radius: 6px;
	text-align: center;
	outline: none;
}

.multi-range-slider .thumb .caption {
	bottom: -15px;
}
.multi-range-slider .thumb .caption > span {
	background: transparent;
	color: #000000;
	height: auto;
	min-width: unset;
	min-height: unset;
	border: none;
	outline: none;
	box-shadow: none;
	border-radius: unset;
	padding: 0;
	font-size: 10px;
	font-weight: 400;
	line-height: 14px;
}

.block-offer-edit-form-field-ref {
	margin-top: 20px;
	margin-bottom: 30px;
}
.block-offer-edit-form-field-ref-select {
	position: relative;
}
.block-offer-edit-form-field-ref-select-photo {
	width: 27.32px;
	height: 27.32px;
	border-radius: 100%;
	background: linear-gradient(-16deg, rgba(42,39,76,.8) 0%, #5C8CC9 100%);
	padding: 5px;
	overflow: hidden;
}
.block-offer-edit-form-field-ref-select-photo > div {
	width: 100%;
	height: 100%;
	border-radius: 100%;
	overflow: hidden;
}
.block-offer-edit-form-field-ref-select-photo > div > img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}
.block-offer-edit-form-field-ref-select-selected {
	width: 275px;
	height: 33px;
	border-radius: 10px;
	border: 1px solid #2A274C4D;
	padding: 3px 14px;
	display: flex;
	align-items: center;
	grid-column-gap: 10px;
	cursor: pointer;
}
.block-offer-edit-form-field-ref-select-selected span {
	font-size: 18px;
	font-weight: 900;
	line-height: 21.6px;
}
.block-offer-edit-form-field-ref-select-dropdown {
	position: absolute;
	width: 100%;
	display: flex;
	flex-direction: column;
	grid-row-gap: 5px;
	padding: 3px 14px 14px;
	border-radius: 10px;
	border: 1px solid #2A274C4D;
	background: #FFFFFF;
	z-index: 2;
}
.block-offer-edit-form-field-ref-select-dropdown-item {
	display: flex;
	align-items: center;
	grid-column-gap: 10px;
	cursor: pointer;
}
.block-offer-edit-form-field-ref-select-dropdown-item.active span {
	font-weight: 700;
}

.block-offer-edit-form-field-content {
	flex-direction: column;
	align-items: flex-start;
	grid-row-gap: 12px;
	margin-bottom: 45px;
}
.block-offer-edit-form-field-content > div:first-child {
	display: flex;
}
.block-offer-edit-form-field-content > div:last-child {
	width: 100%;
}

.ql-container.ql-snow {
	height: 450px;
}

.block-offer-edit-form-buttons {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.block-offer-edit-form-buttons > div {
	display: flex;
	align-items: center;
	grid-column-gap: 20px;
}
.block-offer-edit-form-buttons button {
	width: 255px;
}
.btn-icon-settings:before,
.btn-icon-settings:after {
    -webkit-mask-image: url('@/assets/images/offers/settings.svg');
    mask-image: url('@/assets/images/offers/settings.svg');
}
