.block-chat > .block-wrap {
    padding: 0;
    display: flex;
}

.block-chat-dialog {
    width: 778px;
    height: 866px;
    margin-left: -1px !important;
    margin-top: -1px !important;
    margin-bottom: -1px !important;
    z-index: 2 !important;
}
.block-chat-dialog.show {
    width: 778px;
}
.block-chat-dialog .block-wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.block-chat-dialog-heading {
    display: flex;
    align-items: center;
    grid-column-gap: 10px;
}
.block-chat-dialog-heading span {
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
    white-space: nowrap;
}
.block-chat-dialog-line {
    position: relative;
    width: 100%;
    border-bottom: 1px dashed rgba(0,0,0,.5);
    z-index: 1;
}
.block-chat-dialog-line.top {
    margin-top: 40px;
    margin-bottom: 20px;
}
.block-chat-dialog-line.bottom {
    margin-top: 20px;
    margin-bottom: 40px;
}
.block-chat-dialog-line:before,
.block-chat-dialog-line:after {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    top: calc(50% - 3px);
    background: rgba(0,0,0,.8);
    border-radius: 50%;
    z-index: 2;
}
.block-chat-dialog-line:before {
    left: -3px;
}
.block-chat-dialog-line:after {
    right: -3px;
}
.block-chat-dialog-messages {
    height: 100%;
    max-height: 331.25px;
    overflow-y: scroll;
}
.block-chat-dialog-messages.no-messages {
    justify-content: center;
    align-items: center;
}
.block-chat-dialog-messages.no-messages span {
    font-size: 18px;
    font-weight: 500;
    line-height: 21.6px;
    white-space: nowrap;
}
.block-chat-dialog-message {
    width: 100%;
    border-radius: 6px;
    border: 1px solid #2A274C4D;
    padding: 0 12px;
    background: #2A274C0D;
}
.block-chat-dialog-message:not(:first-child) {
    margin-top: 15px;
}
.block-chat-dialog-message.own {
    background: #F2D70F0A;
}
.block-chat-dialog-message-content {
    font-size: 15px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
}
.block-chat-dialog-message-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.block-chat-dialog-message-sender,
.block-chat-dialog-message-datetime {
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
}
.block-chat-dialog-message-sender {
    text-align: left;
}
.block-chat-dialog-message-sender span {
    font-weight: 700;
}
.block-chat-dialog-message-datetime {
    text-align: right;
}
.block-chat-dialog-form {

}
.block-chat-dialog-form textarea {
    height: 234px;
    resize: none;
}

.block-chat-users {
    width: 100%;
    height: 864px;
    border: none !important;
}
.block-chat-users.collapsed {
    width: auto;
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
}
.block-chat-users-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-column-gap: 15px;
}
.block-chat-users-search span {
    width: 100%;
    flex: 1;
}
.block-chat-users-list {
    display: flex;
    flex-direction: column;
    height: 720px;
    overflow-y: scroll;
    grid-row-gap: 14.25px;
    margin: 20px 0;
}
.block-chat-user-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.block-chat-user-item > div {
    display: flex;
    align-items: center;
    grid-column-gap: 13.25px;    
}
.block-chat-user-item_photo {
    flex: 0 0 34.75px;
    max-height: 34.75px;
    width: 34.75px;
    height: 34.75px;
    border-radius: 50%;
    overflow: hidden;
/*    padding: 7px;*/
    background: linear-gradient(-16deg, rgba(42,39,76,.8) 0%, #5C8CC9 100%);
    display: flex;
    align-items: center;
    justify-content: center;
}
.block-chat-user-item_photo span {
    flex: 0 0 29.84px;
    max-width: 29.84px;
    height: 29.84px;
    border-radius: 50%;
/*    border: 5px solid #FFFFFF;*/
    overflow: hidden;
}
.block-chat-user-item_photo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.block-chat-user-item p {
    font-size: 18px;
    font-weight: 500;
    line-height: 21.6px;
    white-space: nowrap;
}
.block-chat-user-item.selected p {
    font-weight: 900;
}
.block-chat-user-item.unread p {
    font-weight: 900;
}
.block-chat-user-item-unread {
    width: 9px;
    height: 9px;
    background: #5C8CC9;
    border-radius: 100%;
    margin-right: 15px;
}
