.block-content-more a span:nth-child(2),
.show-content > .block-content-more a span:nth-child(1) {
    display: inline !important;
}
.show-content > .block-content-more a span:nth-child(2) {
    display: inline !important;
}

.block-skills-more a span:nth-child(2),
.show > .block-skills-more a span:nth-child(1) {
    display: inline !important;
}
.show > .block-skills-more a span:nth-child(2) {
    display: inline !important;
}

.block-editProfileInterests-fields,
.block-editProfileInterests-fields-item_value > span.form-field-plus {
	z-index: unset !important;
}

.block-editProfileInterests-fields-item_menu {
	position: absolute;
  	z-index: 5;
	top: 54px;
	width: calc(100% - 9px);
	height: auto;
	background: white;
	border: 1px solid rgba(42, 39, 76, 0.3);
  	border-radius: 15px;
  	padding: 14px 16px;
  	display: flex;
  	flex-direction: column;
  	grid-row-gap: 10px;
}

.block-editProfileInterests-fields-item_menu-item {
	cursor: pointer;
}

.block-editProfileWorks-fields1 {
	z-index: 2 !important;
}

.form-field-error input[type="text"],
.form-field-error input[type="email"],
.form-field-error input[type="password"],
.form-field-error textarea {
    border-color: #C0202E;
    border-width: 2px;
}
.form-field-error {
    position: relative;
}
.form-field-icon {
    position: absolute;
    top: 50%;
    right: 4px;
    width: 18px;
    height: 18px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%);
    font-size: 15px;
    font-weight: 900;
    z-index: 1;
}
.form-field-error .form-field-icon {
    background: #C0202E;
    color: #FFFFFF;
}

.block-user_head-profession {
    padding: 0 8px;
}
.block-user_head-profession:before {
    width: 100% !important;
    left: 0 !important;
}
