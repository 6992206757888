.block-projects-item_description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: 50.4px;
    margin: 0;
}

.block-projects-item_description.expanded {
    -webkit-line-clamp: unset;
    max-height: none;
}

.block-projects-widgets {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 42px auto;
}
.block-projects-widget {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-row-gap: 25px;
}
.block-projects-widget-title {
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    color: #C0202E;
}
.block-projects-widget-body {
    width: 100%;
    height: 127px;
    border-radius: 10px;
    border: 0.5px solid #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px 0;
}
.block-projects-widget-body-delimiter {
    position: relative;
    width: 0;
    height: 100%;
    border-left: 1px dashed rgba(0,0,0,.5);
    z-index: 1;
}
.block-projects-widget-body-delimiter:before,
.block-projects-widget-body-delimiter:after {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    left: calc(50% - 3px);
    background: rgba(0,0,0,.8);
    border-radius: 50%;
    z-index: 2;
}
.block-projects-widget-body-delimiter:before {
    top: -3px;
}
.block-projects-widget-body-delimiter:after {
    bottom: -3px;
}
.block-projects-widget-body-item {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 14px;
}
.block-projects-widget-body-item-caption {
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: center;
    color: #101010CC;
}
.block-projects-widget-body-item-value {
    font-size: 26px;
    font-weight: 400;
    line-height: 31.2px;
    text-align: center;
    color: #101010CC;
}

.block-projects-subtitle {
    font-size: 16px;
    line-height: 31px;
    text-align: center;
    color: #C0202E;
    margin-bottom: 35px;
}
