.block-manage-head {
	display: flex;
	grid-column-gap: 30px;
}
.block-manage-logo {
	width: 128px;
	height: 128px;
}
.block-manage-logo img {
	width: 100%;
	height: 100%;
	object-fit: contain;
	object-position: center;
}

.block-manage-budget .block-budget_wrap {
	justify-content: flex-start;
	column-gap: 17px;
}
.block-manage-budget .block-budget_left {
	flex-basis: 204px;
	width: 204px;
}
.block-budget_items {
    display: flex;
	align-items: flex-start;
	column-gap: 33px;
}
.block-budget_item {
    width: 385px;
}
.block-budget_item-title {
	font-weight: 700;
	font-size: 24px;
	line-height: 200%;
	color: #2A274C;
}
.block-budget_item-period {
	font-weight: 700;
	font-size: 15px;
	line-height: 200%;
	color: rgba(42, 39, 76, 0.8);
}
.block-budget_item-sum {
	font-weight: 700;
	font-size: 24px;
	line-height: 200%;
	color: rgba(42, 39, 76, 0.8);
}

.block-actions {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 60px auto 140px;
}
.block-actions-item {
	width: 298px;
	height: 132px;
	border: 0.5px solid #2A274C;
	border-radius: 8px;
	display: flex;
	align-items: center;
	column-gap: 25px;
	padding: 0 30px;
}
.block-actions-item.dark {
	background: #2A274C;
}
.block-actions-item-right {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	row-gap: 16px;
}
.block-actions-item-text {
	font-weight: 700;
	font-size: 14px;
	line-height: 120%;
	color: rgba(16, 16, 16, 0.8);
}
.block-actions-item.dark .block-actions-item-text {
	color: #FFFFFF;
}
.block-actions-item-button {
	min-width: 120px;
	height: 34px;
	background: #2A274C;
	border: 1px solid rgba(42, 39, 76, 0.5);
	border-radius: 8px;
	padding: 0 20px;
	font-weight: 700;
	font-size: 12px;
	line-height: 14px;
	color: #FFFFFF;
	outline: none;
	box-shadow: none;
	cursor: pointer;
}
.block-actions-item.dark .block-actions-item-button {
	background: #FFFFFF;
	color: #2A274C;
}

.block-transactions {
	display: flex;
	flex-direction: column;
	row-gap: 27px;
	margin-bottom: 90px;
}
.block-transactions-title {
	font-weight: 700;
	font-size: 24px;
	line-height: 29px;
	color: #2A274C;
}
.block-transactions-table {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(2, 1fr) 3fr 2fr repeat(3, 1fr);
    gap: 15px;
}
.block-transactions-table-row {
	display: contents;
	cursor: pointer;
}
.block-transactions-table-row > div {
	text-align: left;
	padding: 0;
	font-weight: 700;
	font-size: 14px;
	line-height: 17px;	
	color: rgba(42, 39, 76, 0.9);
}
.block-transactions-table-head {
	cursor: default;
}
.block-transactions-table-head > div {
	padding: 6px 0;
	border-bottom: 1px solid rgba(42, 39, 76, 0.5);
	color: rgba(42, 39, 76, 0.7);
}
.block-transactions-table-cell-direction {
	display: flex;
	align-items: center;
	column-gap: 5px;
}
.block-transactions-table-cell-direction span {
	width: 14px;
	height: 13px;
	border-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 700;
	font-size: 14px;
	line-height: 17px;
	color: #FFFFFF;
}
.block-transactions-table-cell-direction.status-0 span {
	background-color: #FFCC66 !important;
}
.block-transactions-table-cell-direction.direction-income span {
	background-color: #63B2E3;
}
.block-transactions-table-cell-direction.direction-expense span {
	background-color: #C0202E;
}
.block-transactions-table-cell-amount, 
.block-transactions-table-cell-saldo {
	text-align: right;
}
.block-transactions-table-cell-amount.amount-expense {
	color: #C0202E;
}

.manage-dialog-wrap {
	width: 100vw;
	height: 100vh;
	position: fixed;
	z-index: 10;
	top: 0;
	left: 0;
	background: transparent;
	display: flex;
	justify-content: center;
	align-items: center;
}
.manage-dialog {
	width: 781px;
	max-height: 769px;
	background: linear-gradient(0deg, rgba(217, 217, 217, 0.1), rgba(217, 217, 217, 0.1)), #FFFFFF;
	border: 0.5px solid #2A274C;
	box-shadow: 1px 1px 0px #2A274C;
	border-radius: 10px;
}
.manage-dialog-head {
	width: 100%;
	height: 69px;
	border-radius: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-left: 25px;
	padding-right: 20px;
}
.manage-dialog-head.dark {
	background: linear-gradient(0deg, rgba(217, 217, 217, 0.1), rgba(217, 217, 217, 0.1)), #2A274C;
	border: 0.5px solid #2A274C;
	box-shadow: 1px 1px 0px #2A274C;
}
.manage-dialog-title {
	display: flex;
	align-items: center;
	column-gap: 12px;
}
.manage-dialog-title img {
	height: 30px;
}
.manage-dialog-title span {
	font-weight: 700;
	font-size: 24px;
	line-height: 29px;
	color: #344154;
}
.manage-dialog-head.dark .manage-dialog-title span {
	color: #FFFFFF;
}
.manage-dialog-close {
	width: 20px;
	height: 20px;
	cursor: pointer;
}
.manage-dialog-close img {
	width: 100%;
	height: 100%;
}
.manage-dialog-body {
	padding: 30px 60px 60px;
	max-height: 700px;
	overflow-y: scroll;
}
.manage-dialog-body::-webkit-scrollbar {
	display: none;
}  
.manage-dialog-body {
	-ms-overflow-style: none;
	scrollbar-width: none;
} 

.manage-dialog-form-field {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	padding: 0 !important;
}
.manage-dialog-form-field.contractor {
	margin-bottom: 28px;
}
.manage-dialog-form-field label {
	width: 163px;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	color: #101010;
}
.manage-dialog-form-field input {
	width: 499px !important;
	height: 31px;
	border-radius: 6px !important;
	border: 1px solid #2A274C;
	padding: 0 10px;
	font-size: 16px;
	line-height: 140%;
}
.manage-dialog-form-field .select-field {
	width: 236px;
	height: 31px;
}
.manage-dialog-form-field .select-field .search-select div[class*="-control"] {
	border-radius: 6px !important;
	border: 1px solid #2A274C;
	padding: 0 10px !important;
	font-size: 16px !important;
	line-height: 140% !important;
}
.manage-dialog-form-address {
	margin-top: 26px;
	align-items: flex-start;
}
.manage-dialog-form-field-grid {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	width: 499px !important;
	column-gap: 20px;
}
.manage-dialog-form-field.xs input, 
.manage-dialog-form-field.xs select {
	width: 86px !important;
}
.manage-dialog-form-field.sm input, 
.manage-dialog-form-field.sm select {
	width: 178px !important;
}
.manage-dialog-form-field.md input, 
.manage-dialog-form-field.md select {
	width: 297px !important;
}
.manage-dialog-form-field.fw {
	width: 100%;
}
.manage-dialog-form-field.fw input, 
.manage-dialog-form-field.fw select, 
.manage-dialog-form-field.fw .select-field {
	width: 100% !important;
}
.manage-dialog-form-field.currency {
	flex: 0 0 163px !important;
	width: 163px !important;
	column-gap: 15px;
}
.manage-dialog-form-field .react-datepicker-wrapper {
	width: 499px !important;
}
.manage-dialog-form-persons {
	margin-top: 15px;
	align-items: flex-start;
}
.manage-dialog-form-persons .manage-dialog-form-field-grid {
	row-gap: 5px;
}
.manage-dialog-form-field-add {
	display: flex;
	align-items: center;
	column-gap: 10px;
	cursor: pointer;
}
.manage-dialog-form-university {
	margin-top: 25px;
	align-items: flex-start;
}
.manage-dialog-form-checkbox {
	display: flex;
	align-items: center;
	column-gap: 10px;
}
.manage-dialog-form-checkbox input[type="checkbox"] {
	width: 16px !important;
	height: 16px !important;
}
.manage-dialog-form-checkbox label {
	font-size: 14px;
	line-height: 17px;
	color: #101010;
}

.manage-dialog-form-files-grid {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 12px;
}
.manage-dialog-form-files-item {
	width: 115px;
	height: 135px;
	border: 0.5px solid #2A274C;
	border-radius: 8px;
}
.manage-dialog-form-files-item .file-upload {
	width: 100%;
	height: 100%;
	padding: 0 16px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	row-gap: 33.5px;
	cursor: pointer;
}
.manage-dialog-form-files-item .file-upload img {
	width: 33px;
	height: 32px;
}
.manage-dialog-form-files-item .file-upload span {
	text-align: center;	
	font-size: 12px;
	line-height: 14px;
	color: #000000;
}
.manage-dialog-form-files-item .file-item {
	width: 100%;
	height: 100%;
	padding: 16px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	row-gap: 10px;
	cursor: pointer;
}
.manage-dialog-form-files-item .file-item span {
	text-align: center;	
	font-size: 12px;
	line-height: 14px;
	color: #000000;
}

.transaction-info {
	margin: 13.5px 0 20.5px;
	font-size: 15px;
	line-height: 18px;
	color: #101010;
}
.transaction-accept {
	display: flex;
	align-items: flex-start;
	column-gap: 10px;	
}
.transaction-accept label {
	font-size: 15px;
	line-height: 18px;
	color: #101010;
}

.application-accept {
	display: flex;
	flex-direction: column;
	row-gap: 10px;
	margin: 40px auto 60px;
}
.application-accept-text {
	font-size: 16px;
	line-height: 19px;
	color: #101010;
}
.application-accept-checkbox {
	display: flex;
	align-items: center;
	column-gap: 10px;
	margin-left: 160px;
}
.application-accept-checkbox input {
	margin: 0;
}
.application-accept-checkbox label {
	font-size: 14px;
	line-height: 17px;
	color: #101010;
}

.manage-dialog-buttons {
	display: flex;
	justify-content: center;
	align-items: center;
	column-gap: 22px;
	margin-top: 50px;
}
.manage-dialog-buttons button {
	width: 232px;
	height: 39px;
}
