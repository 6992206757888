.block-editProject_head {
	display: flex;
	flex-wrap: wrap;
	grid-column-gap: 25px;
}
.block-editProject_head-image {
	border-radius: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.block-editProject_head-image-logo {
	flex-basis: 33%;
	max-width: 33%;
	height: 207px;
}
.block-editProject_head-image-background {
	flex-basis: calc(67% - 25px);
  	max-width: calc(67% - 25px);
	height: 250px;
}
.block-editProject_head-image-1 a.btn {
	width: 127px;
	height: 54px;
	padding: 0;
	text-align: center;
}
.block-editProject_head-fields input,
.block-editProject_head-fields textarea {
	border-radius: 6px !important;
	resize: none;
}
.block-editProject_head-fields input[name="website"] {
	width: 504px;
	height: 33px;

	font-weight: 400;
	font-size: 18px;
	line-height: 22px;
}
.block-editProject_head-fields textarea[name="name"] {
	height: 184px;
	resize: none;

	font-weight: 900;
	font-size: 32px;
	line-height: 38px;
}
.block-editProject_head-fields textarea[name="description"] {
	height: 83px;
	resize: none;

	font-weight: 400;
	font-size: 15px;
	line-height: 200%;
}
.block-editSupport_params {
	margin-bottom: 41px;
}
.block-editSupport_params > div {
	display: flex;
	align-items: center;
}
.block-editSupport_params > div span {
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	color: rgba(16, 16, 16, 0.8);
}
.block-editSupport_params > div span:first-child {
	width: 150px;
}
.block-editSupport_params input {
	height: 31px;
	border-radius: 6px !important;
	font-weight: 400;
	font-size: 16px;
	line-height: 140%;
}
.block-editSupport_params input[name="budget"] {
	width: 105px;
}
.block-editSupport_params > div span:last-child {
	margin-left: 10px;
	line-height: 140%;
	color: #101010;
}
.block-editSupport_partners-list-title,
.block-editSupport_partners-form-title {
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	color: #101010;
}
.block-editSupport_partners-list {
	margin-bottom: 33px;
}
.block-editSupport_partners-list-title {
	margin-bottom: 27px;
}
.block-editSupport_partners-list-items {
	display: grid;
  	grid-template-columns: repeat(5, 1fr);
	grid-column-gap: 28px;
	grid-row-gap: 21px;
}
.block-editSupport_partners-list-item {
	height: 123px;
	border: 0.5px solid #2A274C;
	border-radius: 8px;
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.block-editSupport_partners-form {
	margin-bottom: 56px;
}
.block-editSupport_partners-form-title {
	margin-bottom: 19px;
}
.block-editSupport_partners-form-body {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.block-editSupport_partners-form-body input {
	width: 275px !important;
	height: 33px;
	border-radius: 10px;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
}
.block-editSupport_partners-form-buttons {
	display: flex;
	align-items: center;
	grid-column-gap: 32px;
}
.block-editSupport_partners-form-buttons button:first-child {
	height: 55px;
}
.btn-icon-photo:before,
.btn-icon-photo:after {
    -webkit-mask-image: url(../../assets/images/photo.svg);
    mask-image: url(../../assets/images/photo.svg);
}
.block-editMembers_body {
	display: grid;
	align-items: flex-end;
  	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 22px;
}
.block-editMembers_side {
	display: flex;
	flex-direction: column;
}
.block-editMembers_side-title {
	font-weight: 700;
	color: #5C8CC9;
}
.block-editMembers_side-left .block-editMembers_side-title {
	font-size: 16px;
	line-height: 19px;
	margin-bottom: 12px;
}
.block-editMembers_side-right .block-editMembers_side-title {
	font-size: 24px;
	line-height: 29px;
	margin-bottom: 10px;
}
.block-editMembers_side-body {
	width: 100%;
	height: 547px;
	border: 1.5px solid #5C8CC9;
	border-radius: 6px;
	padding: 20px 15px 34.25px 25px;
	display: flex;
	flex-direction: column;
}
.block-editMembers_side-left-description {
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	color: rgba(16, 16, 16, 0.8);
	margin-right: 12px;
	margin-bottom: 20px;
}
.block-editMembers_side-body span.form-field-arrow {
	margin-bottom: 25px;
}
.block-editMembers_list {
	display: flex;
	flex-direction: column;
	grid-row-gap: 13.25px;
/*	height: 359px;*/
	height: 100%;
	flex: 1;
	overflow-y: scroll;
}
.block-editMembers_item {
	cursor: default !important;
}
.block-editMembers_item-action {
	width: 21px;
	height: 15px;
	margin-right: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
.block-editStages_params {
	margin-bottom: 41px;
}
.block-editStages_params > div {
	display: flex;
	align-items: center;
	grid-column-gap: 6px;
}
.block-editStages_params > div > span {
	width: 120px;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	color: rgba(16, 16, 16, 0.8);
}
.block-editStages_params .react-datepicker-wrapper {
	width: 147px;
}
.block-editStages_params .react-datepicker-popper {
	z-index: 2;
}
.block-editStages_params input {
	height: 31px;
	border-radius: 6px !important;
	font-weight: 400;
	font-size: 16px;
	line-height: 140%;
}
.block-editStages_add {
	display: flex;
	grid-column-gap: 9px;
	cursor: pointer;
	margin-top: 27px;
	margin-bottom: 14px;
}
.block-editStages_add span {
	font-weight: 700;
	font-size: 16px;
	line-height: 16px;
}
.block-editStages_form {
	margin-bottom: 73px;
}
.block-editStages_form .form-field {
	display: flex;
}
.block-editStages_form .form-field > span {
	width: 127px;
	font-weight: 400;
	font-size: 16px;
	line-height: 31px;
	color: rgba(16, 16, 16, 0.8);
	white-space: nowrap;
}
.block-editStages_form .react-datepicker-wrapper {
	width: 147px;
}
.block-editStages_form .form-field > input, 
.block-editStages_form .form-field > textarea {
	width: 100%;
	flex: 1;
}
.block-editStages_form .form-field input {
	height: 31px;
	border-radius: 6px;
	font-size: 16px;
	line-height: 140%;
}
.block-editStages_form .form-field textarea {
	height: 85px;
	border-radius: 6px;
	padding: 6px 15px;
	font-size: 16px;
	line-height: 140%;
}
