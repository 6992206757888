.block-search-field .select-field select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}
.block-search-field .select-field .search-select {
	cursor: pointer;
}
.block-search-field .select-field .search-select div[class*="-control"] {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid rgba(42, 39, 76, 0.3);
  border-radius: 15px;
  padding: 11px 15px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  outline: none;
  color: #101010;
}
.block-search-field .select-field .search-select div[class*="-control"] div {
	margin: 0;
	padding: 0;
}
.block-search-field div[class*="-placeholder"] {
	white-space: nowrap;
	text-overflow: ellipsis;
}
.block-search-field span[class*="-indicatorSeparator"] {
	display: none;
}
.block-search-field div[class*="-indicatorContainer"] {
	position: absolute;
	right: -11px;
	background: #FFFFFF;
	border: 1px solid #2A274C;
	border-radius: 50%;
	width: 22px;
	height: 22px;
	font-size: 17px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: .25s;
	cursor: pointer;
	outline: none;
	transition: .25s;
	padding: 0;
}
.block-search-field div[class*="-menu"] {
	z-index: 2;
}
.form-field-arrow {
	position: relative;
	display: block;
	padding-right: 11px;
	z-index: 1;
}
.form-field-arrow span.button {
	background: #FFFFFF;
	border: 1px solid #2A274C;
	border-radius: 50%;
	position: absolute;
	top: calc(50% - 11px);
	padding-top: 2px;
	right: 0;
	width: 22px;
	height: 22px;
	font-size: 17px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: .25s;
	cursor: pointer;
	outline: none;
	transition: .25s;
}
.block-search-results {
	position: relative;
	height: 653px;
}
@media (max-width: 1449px) {
  .block-search-results {
    height: 590px;
  }
}
@media (max-width: 1279px) {
  .block-search-results {
    height: 590px;
  }
}
.block-search-resultsItem__head {
	align-items: flex-end !important;
}
