.page-loader {
	position: fixed;
}
.page-loader, .data-loader {
	z-index: 100;
}
.data-loader, .image-loader {
	position: absolute;	
}
.page-loader, .data-loader, .image-loader {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: white;
	display: flex;
	justify-content: center;
	align-items: center;
}
.page-loader-loaded, .data-loader-loaded, .image-loader-loaded {
	display: none;
}
.page-loader img, .data-loader img {
	width: 128px;
	height: 128px;
}
.image-loader img {
	width: 64px !important;
	height: 64px !important;
}
